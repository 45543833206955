.ia-pagination-link:hover {
  text-decoration: none;
}

.ia-pagination-arrow {
  background-color: transparent;
  border: none;
  color: $ai-color-light;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.ia-pagination-arrow:hover {
  color: darken($ai-color-light, 10%);
  transform: scale(1.2);
}

.ia-pagination-arrow:disabled {
  color: #ccc;
  cursor: not-allowed;
  transform: none;
}
