.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
  z-index: 9999;
  transition: all, 0.2s, ease-in-out;
  transition-delay: 0.1s;
  opacity: 0;
  visibility: hidden;

  &.inPlace {
    background-color: transparent;
    position: relative;
    margin-top: 2em;
    width: auto;
    height: auto;
    min-height: 0;
    .loadingContainer {
      position: relative;
      margin-left: -20px;
      width: 40px;
      transform: translate(0);
    }
  }

  &.in {
    visibility: visible;
    opacity: 1;
  }

  .loadingContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
}

.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
  background: transparent;
}

.half-circle-spinner .circle {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
  background: transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #42a4ff;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #42a4ff;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
