// WIZZARD

.stepwizard-step p {
  margin-top: 10px;
  text-align: center;
  width: 100%;
  position: absolute;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step a[disabled].btn-primary {
  background-color: white !important;
  color: $primary;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
