body,
html,
#root {
  height: 100%;
}

body {
  overflow-y: scroll;
  opacity: 1 !important;
}

@media screen and (forced-colors: active), (forced-colors: none) {
  html {
    overflow-x: hidden;
  }
}
