.ql-snow .ql-editor {
  min-height: 15rem;
  background: $white;
}

.ql-toolbar.ql-snow {
  font-family: $font-family-base;
}

.ql-container {
  font-family: $font-family-base;
  font-size: $font-size-base;
}

.ql-bubble .ql-editor {
  padding: 0;
}

.ql-bubble .ql-editor.ql-blank::before {
  left: 0;
  right: 0;
}

.ql-bubble .ql-tooltip {
  border-radius: $border-radius-lg;
  font-size: 13px;
}

.ql-smaller > .ql-snow .ql-editor {
  min-height: 7rem;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  right: 15px;
}
