@import '../1-variables/app';

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
.DateInput_input__focused {
  border-bottom: none;
}

.DateInput_input {
  margin: 0px;
  background-color: transparent;
}

.DateInput_input_1 {
  display: block;
  width: 100%;
  height: calc(1.8125rem);
  padding: 0.25rem 0.7rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  border-bottom: none;
  transition: none;
}

.DateRangePickerInput__withBorder {
  padding: 0;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.2rem;
}

.DateInput {
  background: transparent;
}

.CalendarDay__selected_span {
  background: $primary;
  color: $white;
  border: 1px solid #e4e7e7;
}

.CalendarDay__selected {
  background: $secondary;
  color: $white;
  border: 1px solid #e4e7e7;
}

.CalendarDay__selected:hover {
  background: $primary;
  color: $white;
  border: 1px solid #e4e7e7;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $secondary;
  border: 1px solid #e4e7e7;
  color: $white;
}
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span {
  background: $secondary;
  border: 1px solid #e4e7e7;
}
.DateInput.DateInput_1 {
  width: 38%;
}

.DateRangePickerInput_clearDates,
.DateRangePickerInput_clearDates:focus,
.DateRangePickerInput_clearDates:hover {
  color: red;
}

.DateRangePickerInput_clearDates_svg {
  width: 10px;
  height: 13px;
}

.DateRangePickerInput_arrow_svg {
  width: 18px;
}

.DayPickerNavigation_button:focus,
.DayPickerNavigation_button:hover {
  .DayPickerNavigation_svg__horizontal,
  .DayPickerNavigation_svg__horizontal:focus,
  .DayPickerNavigation_svg__horizontal:hover {
    fill: white;
  }
}
