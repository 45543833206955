.typewriter {
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(90deg, $ai-color-dark, $ai-color-light);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
