/* Chat.scss */

.chat-container {
  max-width: 600px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
}

@media screen and (min-width: 500px) {
  .chat-container {
    min-width: 60%;
  }
}

/* Header */
.chat-header {
  background-color: #2196f3;
  color: white;
  padding: 10px 20px;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-header-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-header-title {
  font-size: 18px;
  font-weight: bold;
}

.chat-header-status {
  font-size: 12px;
  color: lightgreen;
}

.chat-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

/* Body */
.chat-body {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  height: 70vh;
  border: 1px solid #ddd;
  overflow: hidden;
}

/* Mensajes */
.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.message-item {
  display: flex;
  position: relative;
  margin: 5px 0;

  &.message-self {
    justify-content: flex-end;
  }

  &.message-admin {
    background-color: rgb(240, 244, 248, 1);
    border-radius: '2px';
  }

  &.message-deleted .message-bubble {
    background-color: #f76363;
    color: black;
  }
}

/* Burbuja de mensaje */
.message-bubble {
  padding: 10px 15px;
  border-radius: 18px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 70%;
  word-break: break-word;
  position: relative;
  background-color: #ffffff;
  color: black;

  .message-self & {
    background-color: #2196f3;
    color: white;
  }
}

.message-sender {
  font-size: 1rem;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-text {
  margin-top: 5px;
  font-size: 1rem;
}

.message-time {
  font-size: 0.85rem;
  color: gray;
  text-align: left;
  margin-top: 5px;

  &.self {
    text-align: right;
  }
}

/* Botón de eliminar mensaje */
.message-delete-button {
  color: #f76363;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: white;
  border-color: white;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Etiqueta de eliminado */
.message-deleted-label {
  color: #f76363;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* Footer / Input */
.chat-footer {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #ffffff;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 25px;
}

.send-button {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.chat-header-status {
  font-size: 0.9rem;
  color: gray;
}

.chat-header-status.online {
  color: green;
}

.chat-header-status.offline {
  color: red;
}

.chat-header-status.disconected {
  color: rgb(226, 226, 226);
}

.chat-header-info {
  display: flex;
  align-items: center;
}

.chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-header-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.chat-header-status-container {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  margin-top: 4px;
  text-align: center;
}

.chat-header-status-container.online {
  background-color: #e0f8e8;
  color: #2e7d32;
}

.chat-header-status-container.offline {
  background-color: #fdecea;
  color: #c62828;
}

.chat-header-status-container.disconected {
  background-color: #fdecea;
  color: #585858;
}

.chat-header-status-text {
  font-size: 0.9rem;
  font-weight: 500;
}

.chat-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
  transition: color 0.3s;
}

.chat-close-button:hover {
  color: #000;
}

.chat-body.blurred {
  filter: blur(2px);
  pointer-events: none;
}

.reconnect-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.reconnect-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 1rem;
  z-index: 9999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

/* Highlight the admin message bubble differently */
.message-from-admin .message-bubble {
  background-color: #ffee0048;
  border-left: 4px solid #dfb322;
}

.admin-badge {
  margin-left: 0.5rem;
  color: #ff4545;
  font-weight: bold;
}

.double-check-icon {
  color: #0b93f6;
  margin-left: 5px;
  font-size: 1rem;
}
