.emergency-container {
  position: relative;
  width: fit-content;
  max-width: 400px;
  border-radius: 12px;
}

.alert-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff6b6b;
  color: white;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1.2s ease-in-out;
  z-index: 2;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(255, 107, 107, 0.3);
}

.alert-overlay.fade-out {
  opacity: 0;
  pointer-events: none;
}

.message-card {
  color: black;
  border-radius: 12px;
  padding: 8px;
  border: 1px solid black;
  z-index: 1;
  position: relative;
  width: 305px;
}
.message-card-urgent {
  color: black;
  border-radius: 12px;
  padding: 8px;
  box-shadow: 0px 4px 10px rgba(255, 107, 107, 0.3);
  z-index: 1;
  position: relative;
  border: 2px solid #ff6b6b;
}

.important-text {
  margin-bottom: 4px;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-link {
  display: flex;
  align-items: center;
  color: #41a4ff;
  text-decoration: none;
  gap: 4px;
  padding: 4px;
  border-radius: 12px;
  transition: background 0.3s ease-in-out;
  align-self: center;
}

.contact-link:hover {
  background: rgba(255, 107, 107, 0.2);
  text-decoration: none;
}
