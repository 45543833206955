//Defaults

// @import '~/react-widgets/dist/css/react-widgets.css';
// @import '~/react-toastify/dist/ReactToastify.css';
// @import '~/react-dates/lib/css/_datepicker.css'; // default styles for react-dates

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600');

// Variables
@import '1-variables/app';

// Bootstrap
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/mixins';

// Theme mixins
@import '2-mixins/button';
@import '2-mixins/modal';
@import '2-mixins/switch';
@import '2-mixins/tabs';
@import '2-mixins/wizard';
@import '2-mixins/profileImg';

// Theme components
@import '3-components/accordion';
@import '3-components/alert';
@import '3-components/avatar';
@import '3-components/badge';
@import '3-components/buttons';
@import '3-components/card';
@import '3-components/chart';
@import '3-components/chat';
@import '3-components/dropdown';
@import '3-components/feather';
@import '3-components/footer';
@import '3-components/forms';
@import '3-components/hamburger';
@import '3-components/landing';
@import '3-components/modal';
@import '3-components/navbar';
@import '3-components/reboot';
@import '3-components/progress';
@import '3-components/sidebar';
@import '3-components/sizing';
@import '3-components/tables';
@import '3-components/tabs';
@import '3-components/timeline';
@import '3-components/type';
@import '3-components/wrapper';
@import '3-components/content';
@import '3-components/main';
@import '3-components/settings';
@import '3-components/ie';
@import '3-components/candidatecard';
@import '3-components/clientcard';
@import '3-components/profileImg';
@import '3-components/emergencyReminder';
@import '3-components/reactdate';
@import '3-components/unstuckIssuesForm';
@import '3-components/typewriter';
@import '3-components/pagination';

// Theme utilities
@import '4-utilities/borders';
@import '4-utilities/cursors';
@import '4-utilities/overflow';
@import '4-utilities/position';
@import '4-utilities/shadows';
@import '4-utilities/margins';

// 3rd party plugins
//@import "react-quill/dist/quill.bubble";
@import 'react-quill/dist/quill.snow';
// @import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min';
// @import "jvectormap-next/jquery-jvectormap";
// @import "react-dragula/dist/dragula";
// @import "fullcalendar/dist/fullcalendar";

// 3rd party plugin styles
// @import "5-vendor/fullcalendar";
@import '5-vendor/perfect-scrollbar';
@import '5-vendor/quill';
@import '5-vendor/rwcalendar';

// @import "5-vendor/react-bootstrap-table";
// @import "5-vendor/react-datetime";
@import '5-vendor/react-select';
//@import "5-vendor/wizard";
@import '5-vendor/toastr';
@import '5-vendor/googleButton';
@import '5-vendor/react-av-forms';

@import 'loading';
@import 'wizard';
