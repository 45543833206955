.candidateCard {
  position: relative;
  border-radius: 10px;
  width: 300px;
  padding: 10px;

  &:hover {
    background: #f5fcff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    .candidateCard__name {
      color: #007bff;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__content {
    position: absolute;
    display: flex;
    align-items: top;
    justify-content: left;
    width: 100%;
    height: 100%;
    top: calc(100% - 80px);
    left: 0;
    opacity: 1;
    transition: all 0.75s ease-in-out;

    &-inner {
      position: relative;
      flex-direction: row;
      display: flex;
      text-align: center;
      justify-content: center;
      transition: all 0.75s ease-in-out;
    }
  }
  &__title {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__name {
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    line-height: 1.5;
    padding: 10px;
    background-color: transparent;
    border-radius: 4px;
    text-align: left;
  }

  &__visibility {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    top: 6px;
    right: 6px;
  }

  &__img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
    margin: 10px;
    border-radius: 50%;
    object-fit: cover;
    padding: 5px;
    transition: transform 0.5s ease-in-out;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__declined {
    opacity: 0.5;
  }
}

.candidateCard:hover .candidateCard__img {
  transform: scale(1.1);
}

.candidateCard__img2 {
  width: 200px;
  height: 200px;
  object-fit: contain;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.candidateCard:hover .candidateCard__content {
  top: 0;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-item {
  display: flex;
  align-items: center;
}

.info-title {
  font-size: 1rem;
  font-weight: bolder;
}

.info-value {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 0.2rem;
}
