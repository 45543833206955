.rw-calendar-btn-view.rw-btn.rw-btn-primary {
  color: #42a4ff;
}

.rw-calendar-header {
  padding: 8px;
  background-color: white;
}

.rw-cell.rw-state-selected,
.rw-cell.rw-state-selected:hover {
  background-color: #42a4ff !important;
  border-color: #42a4ff !important;
  color: white !important;
}

.rw-calendar .rw-btn {
  color: #42a4ff !important;
  font-weight: 700;
  background-color: white;
}
