.custom-input {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.custom-input:focus {
  border-color: var(--bs-secondary);
  box-shadow: 0 0 5px 3px rgba(28, 146, 255, 0.5);
}

.custom-input-error {
  border-color: var(--bs-danger);
  box-shadow: 0 0 5px 3px rgba(223, 32, 51, 0.5);
}
