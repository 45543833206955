.card {
  > .dataTables_wrapper .table.dataTable,
  > .table,
  > .table-responsive .table,
  > .table-responsive-sm .table,
  > .table-responsive-md .table,
  > .table-responsive-lg .table,
  > .table-responsive-xl .table {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

    td:first-child,
    th:first-child {
      border-left: 0;
      padding-left: $spacer * 1.25;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      padding-right: $spacer * 1.25;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header + .table {
    border-top: 0;
  }
}

.table-action {
  a {
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }

  .feather {
    width: 18px;
    height: 18px;
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.card > .dataTables_wrapper {
  .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding: 1rem 1.25rem;
  }

  .dataTables_paginate {
    padding: 0.6rem 1.25rem;
  }
}

.dt-bootstrap4 {
  width: calc(100% - 2px);
}

.table-striped tbody tr:not(.table-warning):not(.table-danger) {
  --bs-table-striped-bg: #f2f9ff;
  background-color: var(--bs-table-striped-bg);
}

.table-striped tbody tr:not(.table-warning):not(.table-danger) {
  --bs-table-accent-bg: #ffffff;
  background-color: var(--bs-table-accent-bg);
}

.table-striped tbody tr.table-warning:not(.table-danger) {
  background-color: #fff3cd !important;
  --bs-table-striped-bg: #fff3cd;
  background-color: var(--bs-table-striped-bg);
  --bs-table-accent-bg: #fff3cd;
  background-color: var(--bs-table-accent-bg) !important;
}

.table-striped tbody tr.table-danger:not(.table-warning) {
  --bs-table-striped-bg: #eeb4b4; 
  background-color: var(--bs-table-striped-bg) !important;
  --bs-table-accent-bg: #eeb4b4;
  background-color: var(--bs-table-accent-bg) !important;
}

.table-striped tbody tr th h {
  color: inherit !important;
}

.table-striped thead tr th {
  color: inherit !important;
}
