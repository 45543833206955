@mixin profileImg($width: 120px) {
  width: $width;
  height: $width;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}
