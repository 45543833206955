// AppStack
@import 'app';

.hide {
  display: none !important;
}

.blue {
  color: $primary;
}
.blue2 {
  color: $secondary;
}

.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

#notification-wrapper,
.toast-notification {
  z-index: 9999 !important;
}

.bold {
  font-weight: bold;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: grey;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.modal-dialog {
  max-width: 1000px;
  .modal-header,
  .modal-body {
    padding: 1.5rem 2rem;
    background-color: white;
  }
}

.chat {
  z-index: 9999;
  max-width: 400px;
  position: fixed;
  bottom: 0;
  right: 3rem;
  .card {
    margin-bottom: 0;
    .card-header {
      background-color: $secondary;
      padding: 0.5rem;
      color: white;
      a {
        color: white;
      }
    }
    .card-body {
      border: 1px solid $secondary;
      padding-bottom: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    width: 5px;
    background: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    width: 1em;
    background-color: #ddd;
    outline: 1px solid slategrey;
    border-radius: 1rem;
  }

  .text-small {
    font-size: 0.9rem;
  }

  .messages-box,
  .chat-box {
    height: 400px;
    overflow-y: scroll;
  }

  .rounded-lg {
    border-radius: 0.5rem;
  }

  input::placeholder {
    font-size: 0.9rem;
    color: #999;
  }
}

.report {
  .info {
    padding: 1.5rem 2rem;
    border: 1px solid lightgray;
    width: fit-content;
    line-height: 1.5rem;
  }
}

.checkout-modal {
  max-width: 550px;
}

.StripeElement {
  display: block;
  margin: 10px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.301) 0px 1px 2px,
    rgba(0, 0, 0, 0.13) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

// LANDING
.landing {
  .substitute-card {
    background-color: #f5f9fc;
    border: 1px solid #e0e0e0;
    padding: 1.5rem;
  }
}

// select-react
div.css-yk16xz-control {
  min-height: 35px;
}

div.css-1hwfws3 {
  padding: 0 8px;
}

div.react-select-container {
  font-size: 0.9rem;
}
div.css-tlfecz-indicatorContainer {
  padding: 0;
  margin: 0;
}
div.css-1pahdxg-control {
  min-height: 35px;
}
div.css-1gtu0rj-indicatorContainer {
  padding: 0;
  margin: 0;
}

// info color button
.btn-info {
  color: #fff;
  background-color: #3369e8;
  border-color: #3369e8;
}

.rw-list-option:empty {
  display: none;
}

.print {
  display: none;
}

.list-group-item-bg-white {
  background-color: white;
}

// .page {
//   width: 297mm !important;
//   min-height: 210mm !important;
//   margin: 10mm auto !important;
//   padding-right: 20mm;
//   padding-left: 20mm;
//   border: 1px #D3D3D3 solid;
//   border-radius: 5px;
//   background: white;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
// }

@page {
  size: A4 !important;
  margin: 10mm auto !important;
}

@media print {
  header,
  footer,
  aside,
  nav,
  form,
  iframe,
  button {
    display: none !important;
  }

  html,
  body {
    width: 297mm;
    height: 210mm;
  }
  .page {
    width: 297mm !important;
    min-height: 210mm !important;
    margin: 10mm auto !important;
    padding-right: 20mm;
    padding-left: 20mm;
    page-break-after: always;
  }

  .print {
    display: block;
  }
}

.crossOut {
  position: relative;
  overflow: hidden;
}

.crossOut::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: red;
  transform: rotate(-45deg);
  opacity: 50%;
}

.opacity {
  opacity: 0.9;
}

.opacity:hover {
  opacity: 1;
}

.fc-widget-normal {
  // padding: 1.5rem;
  margin-bottom: 20px;
  // bottom: 150px;
}

.step-indicator {
  padding-left: 0;
  text-align: center;
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #fff;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 0 10px;
}

.step.active .circle {
  background: #007bff;
  color: #fff;
}

.step-text {
  display: block;
}

.line-connecter-after {
  position: absolute;
  border-top: 2px solid #b9d1eb;
  top: 20px;
  left: 50%;
  z-index: 0;
  width: 50%;
}

.line-connecter-before {
  position: absolute;
  border-top: 2px solid #b9d1eb;
  top: 20px;
  z-index: 0;
  width: 50%;
}

.step.active .line-connecter-before {
  border-top: 2px solid #007bff;
}

.step.active .line-connecter-after {
  border-top: 2px solid #007bff;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.4;
}

.box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}

a.link-no-underline {
  text-decoration: none;
}

a.link-no-underline:hover {
  text-decoration: none;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: underline;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.fit-content {
  width: fit-content;
}

.margin-circle {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
}

.margin-high {
  background-color: #28a745;
  border: 2px solid #218838;
}

.margin-medium {
  background-color: #007bff;
  border: 2px solid #0067cc;
}

.margin-low {
  background-color: #ffc107;
  border: 2px solid #d69e06;
}

.margin-negative {
  background-color: #dc3545;
  border: 2px solid #b02a37;
}

.border-dashed {
  border-style: dashed !important;
}

.shiny {
  position: relative;
}

.shiny .shine-overlay {
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(var(--shine-color-rgb), 0) 0%,
    rgba(var(--shine-color-rgb), 0.3) 40%,
    rgba(var(--shine-color-rgb), 0.6) 50%,
    rgba(var(--shine-color-rgb), 0.3) 60%,
    rgba(var(--shine-color-rgb), 0) 100%
  );
  transform: skewX(-10deg);
  animation: shine-loop 5s ease-in-out infinite;
  animation-fill-mode: forwards;
  pointer-events: none;
  z-index: 1;
  opacity: 1;
}

@keyframes shine-loop {
  0% {
    left: -150%;
    opacity: 1;
  }
  20% {
    left: 100%;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

.ia-shadow {
  box-shadow: 2px 4px 6px rgba($ai-color-light, 0.2),
    0 1px 3px rgba($ai-color-light, 0.15);
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.ia-shadow:hover {
  box-shadow: 2px 8px 12px rgba($ai-color-light, 0.3),
    0 2px 4px rgba($ai-color-light, 0.2);
}

.ia-shadow:disabled {
  box-shadow: 2px 4px 6px rgba(248, 249, 250, 0.8),
    0 1px 3px rgba(248, 249, 250, 0.5);
  background-color: #f8f9fa;
  cursor: not-allowed;
  filter: brightness(0.95);
}

.ia-color-light {
  color: $ai-color-light;
}

.ia-color-dark {
  color: $ai-color-dark;
}

.ia-button {
  width: 'fit-content';
  background-color: transparent;
  border: 2px solid;
  color: $ai-color-dark;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba($ai-color-dark, 0.2),
    0 1px 3px rgba($ai-color-light, 0.15);
  transition: all 0.3s ease;
}

.ia-button:hover {
  background-color: $ai-color-dark;
  color: #fff;
  border-color: $ai-color-light;
  box-shadow: 0 6px 12px rgba($ai-color-light, 0.3),
    0 2px 4px rgba($ai-color-dark, 0.2);
}

.ia-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba($ai-color-light, 0.5);
  color: $ai-color-dark;
}

.ia-button:disabled {
  background-color: rgba($ai-color-light, 0.5);
  color: white;
  border-color: rgba($ai-color-light, 0.5);
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.8;
}
.pigeon-ai-button {
  position: relative;
  background-color: #fff;
  color: white;
  border: 2px solid #9f7fff;
  padding: 0.5rem 1.2rem 0.5rem 1.7rem;
  overflow: hidden;
  transition: color 0.5s ease, background-color 0.3s ease,
    border-color 0.3s ease;
}

.pigeon-ai-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: #9f7fff;
  transform: translateX(-100%);
  z-index: 0;
  animation: fillButton 0.5s ease-in forwards;
}

.pigeon-ai-button .liquid {
  position: relative;
  z-index: 1;
  transition: color 0.2s ease;
}

.ia-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
}

.ia-pagination-button {
  background-color: transparent;
  color: $ai-color-light;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba($ai-color-light, 0.3);
}

.ia-pagination-button:hover {
  background-color: $ai-color-light;
  color: white;
  box-shadow: 0 4px 8px rgba($ai-color-light, 0.5);
}

.ia-pagination-button:disabled {
  background-color: rgba($ai-color-light, 0.3);
  color: #ccc;
  border-color: rgba($ai-color-light, 0.3);
  cursor: not-allowed;
  box-shadow: none;
}

.ia-pagination-button.active {
  background-color: darken($ai-color-light, 10%);
  color: white;
  border-color: $ai-color-light;
  box-shadow: 0 4px 8px rgba($ai-color-light, 0.4);
}

.ia-pagination-link {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

@keyframes fillButton {
  0% {
    transform: translateX(-100%);
    color: #6a0dad;
  }
  100% {
    transform: translateX(0);
    color: #fff;
  }
}

.form-check-input:checked {
  background-color: #42a4ff;
  border-color: #75c3ff;
}

.switch-inactive .form-check-input {
  background-color: #f8f9fa;
  border-color: #ced4da;
}

.custom-dropdown-style {
  background-color: white !important;
  transform: translate(-10px, 78px) !important;
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-flex-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

@media (max-width: 1300px) {
  .d-hide-1300 {
    display: none !important;
  }
}

@media (max-width: 1250px) {
  .d-hide-1250 {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .full-text {
    display: none;
  }
  .icon-only {
    display: inline;
  }
}

@media (min-width: 1025px) {
  .full-text {
    display: inline;
  }
  .icon-only {
    display: none;
  }
}

@media (max-width: 440px) {
  .responsive-card {
    flex-direction: column !important;
    text-align: left;
  }

  .responsive-card img {
    margin-bottom: 1rem;
  }
}

.custom-table {
  --bs-table-accent-bg: transparent;
  --bs-table-bg: transparent;
}

.btn-light-hover-primary {
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #173958;
  transition: all 0.3s ease;
}

.btn-light-hover-primary:hover {
  background-color: #173958;
  color: #fff;
  border-color: #173958;
}

.btn-light-hover-secondary {
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #42a4ff;
  transition: all 0.3s ease;
}

.btn-light-hover-secondary:hover {
  background-color: #42a4ff;
  color: #fff;
  border-color: #42a4ff;
}
